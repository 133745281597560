.projectDetails {
	:global{
		.ant-descriptions-item-content {
			width: 100%;
		}
	}
}
.statusIndicator {
	border-radius: 50%;
	max-width: 10px;
	min-width: 10px;
	height: 10px;
	width: 10px;
	margin: 10px 4px 0px 0px;
	float: left;
}

.statusLabel {
	line-height: 30px;
}

.assignment {
	margin-bottom: 6px;
}

.assignment_subtitle {
	color: #aaa
}

.document {
	margin-bottom: 6px;
}