.assignmentinfo {
	margin-top: 5px;
	margin-bottom: 5px;
}

.assignment {
	margin-left: -5px;
}

.assignmentIcon {
	border: 1px solid #fff;
}