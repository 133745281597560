.panel {
	:global{
		.ant-collapse-header {
			background-color: #fafafa;
		}
		.ant-collapse-content-box {
			padding: 0px;
		}
	}
	.element {
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 16px;
		padding-right: 16px;
		border-bottom: 1px solid #d3d3d3;

		.issueStatus {
			:global {
				.NEW {
					background-color: #52c41a;
				}
				.INP {
					background-color: #52c41a;
				}
				.ONH {
					background-color: #fa8c16;
				}
				.ROP {
					background-color: #52c41a;
				}
				.CND {
					background-color: #fa8c16;
				}
				.WTR {
					background-color: #fa8c16;
				}
				.RLV {
					background-color: #fa8c16;
				}
				.VCP {
					background-color: #fa8c16;
				}
				.CLS {
					background-color: #fa8c16;
				}
			}

			.statusStatus {
				:global {
					.NEW {
						background-color: #52c41a;
					}
					.INP {
						background-color: #52c41a;
					}
					.ONH {
						background-color: #fa8c16;
					}
					.ROP {
						background-color: #52c41a;
					}
					.CND {
						background-color: #fa8c16;
					}
					.WTR {
						background-color: #fa8c16;
					}
					.RLV {
						background-color: #fa8c16;
					}
					.VCP {
						background-color: #fa8c16;
					}
					.CLS {
						background-color: #fa8c16;
					}
				}
			}
		}
	}
	.element:last-of-type {
		border-bottom: 0;
	}
}